// Scroll back to the top of the page smoothly
function BackToTop() {
	// Hide #back-top first
	$("#back-top").hide();
	// Fade in #back-top
	$(window).scroll(function () {
		// if ($(this).scrollTop() > 1) {
		//	 $(".navmenu").css("border-bottom", "1px solid #CCCCCC");
		// } else {
		//	 $(".navmenu").css("border-bottom", "");
		// }

		if ($(this).scrollTop() > 100) {
			$("#back-top").fadeIn();
		} else {
			$("#back-top").fadeOut();
		}
	});
	// Scroll body to 0px on click
	$("#back-top a").click(function () {
		$("body,html").animate({
			scrollTop: 0
		}, 800);
		return false;
	});
}

$(document).ready(function() {
	BackToTop();	// Scroll back to the top of the page smoothly

});
