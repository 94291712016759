//Search toggle function
$(document).ready(function (){
	// var searchInput = document.getElementById('search-input');
	// var searchSubmit = document.getElementById('search-submit');
	// if (searchInput.addEventListener) {
 //    	searchInput.addEventListener('keydown', performSearch);
	// } else if (searchInput.attachEvent) { // For IE 8 and earlier versions
	//     searchInput.attachEvent('keydown', performSearch);
	//     console.log(searchInput);
	// }

	// if (searchSubmit.length) {
	// 	searchSubmit.on('click', function(e){
	// 		e.preventDefault();
	// 		performSearch();
	// 		console.log(searchSubmit);
	// 	});
	// }

	if ($(window).width() > 991){
		$(".js-search").click(function(){
			$(".js-searchClose").fadeIn();
			$(".search-wrap").show();
		});
		$(".js-searchClose").click(function(){
			$(".js-searchClose").fadeOut();
			$(".search-wrap").hide();
		});
	}else{
		$(".js-search").click(function(e){
			$(".js-searchClose").fadeIn();
			$(".search-wrap").show();
			$('.navbar-toggler').removeClass('collapsed').attr('aria-expanded','false');
			$('.navbar-expand-lg.navbar-light .navbar-toggler svg').removeClass("fa-times").addClass("fa-bars");
			$(".navbar-collapse").removeClass('show');
		});
		$(".js-searchClose").click(function(){
			$(".js-searchClose").fadeOut().removeClass('collapsed').attr('aria-expanded','false');
			$(".js-search").removeClass('collapsed').attr('aria-expanded','false');
			$(".search-wrap").hide();
		});
	}


});

// search function
function performSearch(e) {
    var key = e.which || e.keyCode;
    if (key === 13) {
        window.location.href = searchInput.getAttribute('data-url') + '?' + searchInput.name + '=' + searchInput.value;
        e.preventDefault();
        console.log(searchInput);
    }
}
