$(document).on('load', function() {
	if ($(window).width() > 991) {
	}else if ($(window).width() < 992) {
		$('.juicer-feed').attr("data-columns","3");
	}else if ($(window).width() < 576) {
		$('.juicer-feed').attr("data-columns","2");
	}else{
		$('.juicer-feed').attr("data-columns","1");
	}
});
