$(window).on('resize', function(){
	if ($(window).width() > 991) {
		$('.navbar .navbar-collapse').removeAttr('style');

		var menu_height = $('.navbar-brand').innerHeight();
		var screen_height = $(window).height();
		var final_height = screen_height - 68;
		$('.navbar .navbar-collapse .nav-item > .dropdown-menu > ul:last-child').css({'max-height':final_height});

	}else{
		$('.navbar-expand-lg.navbar-light .navbar-toggler').click(function(e) {
			$(".search-wrap").fadeOut();
			$(".js-searchClose").fadeOut().removeClass('collapsed').attr('aria-expanded','false');
			$(".js-search").removeClass('collapsed').attr('aria-expanded','false');
			if ( $('.navbar-expand-lg.navbar-light .navbar-toggler').attr('aria-expanded') == 'true'){
				$('.navbar-expand-lg.navbar-light .navbar-toggler svg').removeClass("fa-times").addClass("fa-bars");
			} else {
				$('.navbar-expand-lg.navbar-light .navbar-toggler svg').removeClass("fa-bars").addClass("fa-times");
			}
		});

		var menu_height = $('.navbar-brand').innerHeight();
		var screen_height = $(window).height();
		var final_height = screen_height - menu_height;
		$('.navbar .navbar-collapse').css({'max-height':final_height});
	}

	if ($(window).width() > 767) {
		$('.sitemap .sitehead a:not([class="sitehead-single"])').removeClass('collapsed').removeAttr('data-bs-toggle');
		$('.sitemap .col-md > ul').removeClass('collapse').removeAttr('aria-expanded');
	}else{
		$('.sitemap .f-the-president > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'});
		//$('.sitemap .f-presidents-diary > .sitehead a').attr({'href':'#f_presidents_diary', 'data-bs-toggle':'collapse', 'aria-expanded':'false', 'aria-controls':'f_presidents_diary'});
		$('.sitemap .f-presidents-office > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'});
		$('.sitemap .f-visit-explore > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'});
		$('.sitemap .f-newsroom > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'});

		$('.sitemap .sitehead a:not([class="sitehead-single"])').addClass('collapsed');
		$('.sitemap .col-md > ul').addClass('collapse').attr('aria-expanded','false');
	}
});

$(document).ready(function() {
	if ($(window).width() > 991) {
		$('.navbar .navbar-collapse').removeAttr('style');
		var prevString = "";
		var isMatched = false;
		var prevNode = null;
		$("[id^='isTouch']").on("click touchend", function(e) {
			if (e.type == "touchend") {
				e.preventDefault(); // Prevent main navigation menu first level menu links from following the URL

				if(prevString == ""){
					prevString = $(this).attr("id");
				}else if(prevString == $(this).attr("id")){
					isMatched = true;
				}else{
					isMatched = false;
				}
				// LogMessage($(this).attr("id"));
				// LogMessage($(this).parent().attr("class"));
				// LogMessage($(this).parent().children(":nth-child(2)").attr("class"));

				// for touched tablet only, check if matched
				if(isMatched == true) {
					if(prevNode == null) {
					// LogMessage("MATCHED & CLOSE");
						if($(this).parent().children(":first").attr("class").toLowerCase().indexOf("active") < 0) {
							// $(this).parent().children(":first").css("color", "#FFF"); // .css("color", "#777");
						}
						$(this).parent().children(":nth-child(2)").hide();
					}

					if ((prevNode).parent().children(":nth-child(2)").css("display") == "none") {
						// LogMessage("MATCHED & OPEN");
						// prevNode.parent().children(":first").css("color", "#FFF"); //css("color", "#333");
						prevNode.parent().children(":nth-child(2)").show();
					}else{
						// LogMessage("MATCHED & CLOSE");
						if (prevNode.parent().children(":first").attr("class").toLowerCase().indexOf("active") < 0) {
							// prevNode.parent().children(":first").css("color", "#FFF"); // .css("color", "#777");
						}
						prevNode.parent().children(":nth-child(2)").hide();
					}
				}else{
					// LogMessage("NOT MATCHED");
					prevString = $(this).attr("id");
					// $(this).parent().children(":first").css("color", "#FFF"); //css("color", "#333");
					$(this).parent().children(":nth-child(2)").show();

					if (prevNode != null) {
						if (prevNode.parent().children(":first").attr("class").toLowerCase().indexOf("active") < 0) {
							// prevNode.parent().children(":first").css("color", "#FFF"); // .css("color", "#777");
						}
							prevNode.parent().children(":nth-child(2)").hide();
						}
					prevNode = $(this);
				}
				// LogMessage("-------------");
			}
		});
		$(document).on("touchstart", function (event) {
			if (!$(event.target).closest('.navbar-nav').length) {
				$(".navbar-nav > .nav-item > .dropdown-menu").hide();
			}
		});

		var menu_height = $('.navbar-brand').innerHeight();
		var screen_height = $(window).height();
		var final_height = screen_height - 68;
		$('.navbar .navbar-collapse .nav-item > .dropdown-menu > ul:last-child').css({'max-height':final_height});
	}else{
		$('.navbar-expand-lg.navbar-light .navbar-toggler').click(function(e) {
			$(".search-wrap").fadeOut();
			$(".js-searchClose").fadeOut().removeClass('collapsed').attr('aria-expanded','false');
			$(".js-search").removeClass('collapsed').attr('aria-expanded','false');
			if ( $('.navbar-expand-lg.navbar-light .navbar-toggler').attr('aria-expanded') == 'true'){
				$('.navbar-expand-lg.navbar-light .navbar-toggler svg').removeClass("fa-times").addClass("fa-bars");
			} else {
				$('.navbar-expand-lg.navbar-light .navbar-toggler svg').removeClass("fa-bars").addClass("fa-times");
			}
		});

		var menu_height = $('.navbar-brand').innerHeight();
		var screen_height = $(window).height();
		var final_height = screen_height - menu_height;
		$('.navbar .navbar-collapse').css({'max-height':final_height});
	}
	if ($(window).width() > 767) {
		$('.sitemap .sitehead a:not([class="sitehead-single"])').removeClass('collapsed').removeAttr('data-bs-toggle');
		$('.sitemap .col-md > ul').removeClass('collapse').removeAttr('aria-expanded');
		$('.sitemap .sitehead a:not([class="sitehead-single"])').on('click',(e)=>{
			e.preventDefault()
		})
	}else{
		$('.sitemap .f-the-president > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'}).addClass('collapsed');
		//$('.sitemap .f-presidents-diary > .sitehead a').attr({'href':'#f_presidents_diary', 'data-bs-toggle':'collapse', 'aria-expanded':'false', 'aria-controls':'f_presidents_diary'});
		$('.sitemap .f-presidents-office > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'}).addClass('collapsed');
		$('.sitemap .f-visit-explore > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'}).addClass('collapsed');
		$('.sitemap .f-newsroom > .sitehead a').attr({ 'data-bs-toggle':'collapse', 'aria-expanded':'false'}).addClass('collapsed');

		$('.sitemap .sitehead a:not([class="sitehead-single"])').addClass('collapsed');
		$('.sitemap .col-md > ul').addClass('collapse').attr('aria-expanded','false');
	}

});
