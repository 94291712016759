import $ from 'jquery';
import 'bootstrap';
import 'jquery-match-height';
import './bootstrap-4-navbar.js';
import './bootstrap-tabcollapse.js';
import './fontawesome-all.min.js';
import './navbar.js';
import './juicer-feed.js';
import './jquery.fancybox.min.js';
// import './match-height-jquery.js';
import './grid-tiles.js';
import './fontadjuster.js';
import './onemap-leaflet.js';
import './back-to-top.js';
import './responsive-search-toggle.js';
import objectFitImages from 'object-fit-images';
import './style-masthead.js';

var getUrlParameter = function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
	sURLVariables = sPageURL.split('&'),
	sParameterName,
	i;
	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');
		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

$(window).on('load', function () {
	console.log('window onload')
	callNewsReleasesApi()	
	callSpeechesApi()	
	callGalleryApi()
	callSearchApi()
	customFormStyle()
});




function callGalleryApi(){
	if( $('.photoalbum').data('endpoint')){
		$(window).on('resize', function () {
			if ($(window).width() < 576) {
				$.fancybox.defaults.thumbs.autoStart = false;
			} else {
				$.fancybox.defaults.thumbs.autoStart = true;
				$.fancybox.defaults.thumbs.axis = 'x';
			}
		});
			//newsroom bloglist
			var length = $(".album-list .photoimg").length; //total #
			var acc = 6; //initial # to be displayed
			var prev = 0; //previous #
			$('.album-list .photoimg:lt(' + acc + ')').css({ 'display': 'block' });
			var photoGalleryApi = $('.photoalbum').data('endpoint'),
			noOfItems = 6,
			noToSkip = 0,
			year = getUrlParameter('year') || '',
			query = getUrlParameter('query') || '',
			loadBtn = $('.photoalbum .loadmore');
			retrieveGallery(noOfItems, noToSkip, query, year, loadBtn);
			
			function retrieveGallery(itemCount, itemSkip, queryName, year, btnToHide) {
				noToSkip += 6;
				var data = {
					query: queryName,
					year: year,
					noOfItems: itemCount,
					noToSkip: itemSkip
				};

			$.ajax({
				type: 'GET',
				url: photoGalleryApi,
				data: data,
				dataType: 'json',
				success: function success(data) {
					var albumList = $('.album-list');
					var photoGalleryItems = data.PhotoGalleries,
					noOfItems = data.TotalResults;
				if (noToSkip >= noOfItems) {
					btnToHide.hide();
				}
				processData(photoGalleryItems, albumList);
				var displayGalleryResults = $('.filtered-result-gallery span'),
				currentGalleryResults = parseInt(displayGalleryResults.html()),
				nextGalleryResults = photoGalleryItems.length;
				// loadBtn.click(function(event) {
				// 	event.preventDefault();
				var n = currentGalleryResults + nextGalleryResults;
				displayGalleryResults.html(n);
				// });
				},
				error: function error(_error) {
					console.log(_error);
				}
			});
			}
			loadBtn.on('click',function (event) {
			event.preventDefault();
			retrieveGallery(noOfItems, noToSkip, query, year, $(this));
			});
			function processData(array, container) {
			var markup = '',
			classArray = [];
			array.forEach(function (each, idx) {
			var caption = each.Caption;
			classArray.push(each.Id);
			var mainMarkup = '<a href="' + each.Href + '" class="' + each.Id + '" data-fancybox="' + each.Id + '" data-caption="<h3 class=\'fancybox-caption__title\'><span class=\'caption-date\'>' + each.Date.Day + ' ' + each.Date.Month + ' ' + each.Date.Year + '</span><span class=\'caption-title\'>' + each.Title.replace(/\"/g,"&quot;") + '</span></h3><p>' + each.Caption.replace(/\"/g,"&quot;") + '</p>"><div class="box" style=""><img class="match-height5" src="' + each.Href + '" alt=""><div class="group-caption text-white"><div class="photoimg__date"><span class="day">' + each.Date.Day + '</span><span class="month">' + each.Date.Month + '</span></div><div class="overlay-text"><div class="overlay-title">' + each.Title.replace(/\"/g,"&quot;") + '</div><p class="text-sm hidden">' + each.Text.replace(/\"/g,"&quot;") + '</p></div></div></div></a>';
			var thumbnails = each.Thumbnails,
			thumbnailMarkup = '';
			thumbnails.forEach(function (thumbnail, i) {
			var eachThumbNailMarkup = '<a href="' + thumbnail.Path + '" class="' + each.Id + '" data-fancybox="' + each.Id + '" data-thumb="' + thumbnail.Path + '" data-caption="<h3 class=\'fancybox-caption__title\'><span class=\'caption-date\'>'  + each.Date.Day + ' ' + each.Date.Month + ' ' + each.Date.Year + '</span><span class=\'caption-title\'>' + each.Title.replace(/\"/g,"&quot;") + '</span></h3><p>' + thumbnail.Caption.replace(/\"/g,"&quot;") + '</p>"></a>';
			thumbnailMarkup += eachThumbNailMarkup;
			});
			var urlEach = '<div class="col-12 col-sm-6 col-xl-4 photoimg sb-3" style="display: block;">' + mainMarkup + '<div style="display: none;">' + thumbnailMarkup + '</div></div>';
			markup += urlEach;
			});
			container.append(markup);
			// get an array of all the classnames
			// let thumbClass = $(".photoimg a").each.attr('class');
			// console.log(classArray);
			classArray.forEach(function (each, idx) {
			// console.log(each);
			$('.' + each).fancybox({
			clickContent: false,
			autoSize: true,
			slideShow: {
			autoStart: true,
			speed: 3000
			}
			});
			});
			// console.log(thumbClass);
			}


			retrieveGallery(noOfItems, noToSkip, query, year, loadBtn);
	}
}

function callNewsReleasesApi(){
	if($('.presses').data('endpoint')){
		console.log("presses found")
		var getNewsReleasesApi = $('.presses').data('endpoint'),
		noOfItems = 10,
		noToSkip = 0,
		year = getUrlParameter('year') || '',
		query = getUrlParameter('query') || '',
		pressesContainer = $('.newsroom-list__presses'),
		loadBtn = $('.presses .loadmore');
		function retrieveSpeeches(itemCount, itemSkip, queryName, year, btnToHide) {
			noToSkip += 10;
			var data = {
				query: queryName,
				year: year,
				noOfItems: itemCount,
				noToSkip: itemSkip
			};
			$.ajax({
				type: 'GET',
				url: getNewsReleasesApi,
				data: data,
				dataType: 'json',
				success: function success(data) {
					console.log("fetch success")
				var pressReleasesData = data.NewsReleases,
				totalResults = data.TotalResults;
				if (noToSkip >= totalResults) {
				btnToHide.hide();
				}
				processData(pressReleasesData, pressesContainer);
				var displayResults = $('.filtered-result span'),
				currentResults = parseInt(displayResults.html()),
				nextResults = pressReleasesData.length;
				// loadBtn.click(function(event) {
				// 	event.preventDefault();
				var n = currentResults + nextResults;
				displayResults.html(n);
				// });
				},
				error: function error(_error) {
				console.log(_error);
				}
			});
		}
		loadBtn.on('click',function (event) {
			event.preventDefault();
			retrieveSpeeches(noOfItems, noToSkip, query, year, $(this));
		});
		function processData(array, container) {
			console.log("process data")
			var url = '';
			array.forEach(function (each, idx) {
			var eachMarkup = '\n\t\t\t\t<div class="list-item" style="display: block;">\n            <a href="' + each.Href + '">' + each.Title + '</a>\n\n            <div class="newsroom-date text-grey sb-3">\n                ' + each.Date + '\n            </div>\n        </div>';
			url += eachMarkup;
			});
			container.append(url);
		}
		// call data
		retrieveSpeeches(noOfItems, noToSkip, query, year, loadBtn);
	}
}

function callSpeechesApi(){
	if($('.speeches').data('endpoint')){
		var getSpeechesApi = $('.speeches').data('endpoint'),
		noOfItems = 10,
		noToSkip = 0,
		year = getUrlParameter('year') || '',
		query = getUrlParameter('query') || '',
		speechesContainer = $('.newsroom-list__speeches'),
		loadMoreBtn = $('.speeches .loadmore');

		function retrieveSpeeches(itemCount, itemSkip, queryName, year, btnToHide) {
			noToSkip += 10;
			var data = {
				query: queryName,
				year: year,
				noOfItems: itemCount,
				noToSkip: itemSkip
			}

			$.ajax({
				type: 'GET',
				url: getSpeechesApi,
				data: data,
				dataType: 'json',
				success: function success(data) {
				var speechItems = data.Speeches,
				totalResults = data.TotalResults;
				console.log('noToSkip is ' + noToSkip + ' and totalResults is ' + totalResults);
				if (noToSkip >= totalResults) {
				btnToHide.hide();
				}
				processData(speechItems, speechesContainer);
				var displayResults = $('.filtered-result-speech span'),
				currentResults = parseInt(displayResults.html()),
				nextResults = speechItems.length;
				// loadMoreBtn.click(function(event) {
				// event.preventDefault();
				var n = currentResults + nextResults;
				displayResults.html(n);
				// });
				},
				error: function error(_error) {
				console.log(_error);
				}
			});
		};

		loadMoreBtn.on('click',function (event) {
			event.preventDefault();
			retrieveSpeeches(noOfItems, noToSkip, query, year, $(this));
		});

		function processData(array, container) {
			var url = '';
			array.forEach(function (each, idx) {
			var eachMarkup = '\n\t\t\t\t<div class="list-item" style="display: block;">\n            <a href="' + each.Href + '">' + each.Text + '</a>\n\n            <div class="newsroom-date text-grey sb-3">\n                ' + each.Date + '\n            </div>\n        </div>';
			url += eachMarkup;
			});
			container.append(url);
		}

		retrieveSpeeches(noOfItems, noToSkip, query, year, loadMoreBtn);
	}
}

function callSearchApi(){
	if ($(".search").length && $(".search").data("endpoint")) {
		let totalSearchLength = 0;
		//   let searchResultArray = [];
  
		var getSearchResultsApi = $(".search").data("endpoint"),
		  $searchDisplayTotalResults = $(
			".filtered-result-search .filtered-result-search--cur"
		  ),
		  $searchDisplayTotalItems = $(
			".filtered-result-search .filtered-result-search--total"
		  ),
		  noOfItems = $(".filtered-result-search span").data("attribute"),
		  noToSkip = 0,
		  query = getUrlParameter("q") || "",
		  $searchListResults = $(".search-list__results"),
		  $loadMoreBtn = $(".search-list .loadmore");
  
		$searchDisplayTotalResults.text(totalSearchLength);
  
	
  
		function retrieveSearches(itemCount, itemSkip, queryName, btnToHide) {
  
		  var data = {
			query: queryName,
			noOfItems: itemCount,
			noToSkip: itemSkip,
		  };
  
		  $.ajax({
			type: "GET",
			url: getSearchResultsApi,
			data: data,
			dataType: "json",
			success: function success(data) {
  
			  var searchItems = data.Results,
				totalResults = data.TotalResults;
  
			  totalSearchLength += searchItems.length;
  
			  $searchDisplayTotalResults.text(totalSearchLength);
			  $searchDisplayTotalItems.text(totalResults);
  
			  processData(searchItems, $searchListResults);
  
			  if (totalSearchLength >= totalResults) {
				$loadMoreBtn.hide();
			  } else {
				$loadMoreBtn.show();
			  }
			},
			error: function error(_error) {
			  console.log(_error);
			},
		  });
  
		  noToSkip = totalSearchLength;
		}
  
		$loadMoreBtn.on('click',function (event) {
		  event.preventDefault();
		  let curSearchLength = $searchDisplayTotalResults.text();
		  retrieveSearches(noOfItems, curSearchLength, query, $(this));
		});
  
		function processData(array, container) {
		  var url = "";
		  array.forEach(function (each, idx) {
			var eachMarkup =
			  '\n\t\t\t\t\t<div class="list-item" style="display: block;">\n\t            <a href="' +
			  each.Href +
			  '">\n\t\t\t\t\t\t\t\t<h4>' +
			  each.Title +
			  "</h4>\n\t\t\t\t\t\t\t</a>\n\n\t\t\t\t\t\t\t<p>" +
			  each.Text +
			  '</p>\n\n\t            <div class="text-red text-xs sb-3">\n\t\t\t\t\t\t\t\t<a href="' +
			  each.Href +
			  '" class="text-red">\n\t\t\t\t\t\t\t\t\t' +
			  each.Href +
			  "\n\t\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t</div>\n\t        </div>";
			url += eachMarkup;
		  });
		  container.append(url);
		}
  
		//search result
		let searchNum = $(".filtered-result-search span").data("attribute");
		var length = $(".search-list .list-item").length; //total #
		var acc = searchNum; //initial # to be displayed
		var prev = 0; //previous #
		$(".search-list .list-item:lt(" + acc + ")").css({ display: "block" });

		retrieveSearches(noOfItems, noToSkip, query, $loadMoreBtn);

	  }

}

function customFormStyle(){
	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	var forms = document.getElementsByClassName('needs-validation');
	if(forms){
		// Loop over them and prevent submission
		var validation = Array.prototype.filter.call(forms, function(form) {
			form.addEventListener('submit', function(event) {
			if (form.checkValidity() === false) {
				event.preventDefault();
				event.stopPropagation();
			}
				form.classList.add('was-validated');
			}, false);
		});
	}
}


window.MatchHeight = function() {
	$('.match-height').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height1').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height2').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height3').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height4').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height5').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height6').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height7').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height8').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height9').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height10').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height11').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height12').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height13').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height14').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height15').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height16').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height17').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height18').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height19').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height20').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height30').matchHeight();		// requires jquery.matchHeight-min.js
	$('.match-height100').matchHeight();	// requires jquery.matchHeight-min.js
}



$(function(){
	objectFitImages();


	if ($(window).width() > 991) {
		$('.home .bg-highlight .highlight-img > img').addClass("match-height3");
		$('.home .bg-highlight > .container > .row > .col-12.col-lg-4 > div').addClass("match-height3");
	}else{
		$('.home .bg-highlight .highlight-img > img').removeClass("match-height3");
		$('.home .bg-highlight > .container > .row > .col-12.col-lg-4 > div').removeClass("match-height3");
	}
	MatchHeight(); 

	
	var $filterSearch = $('.filter-search');
	var $filterSearchForm = $('form', $filterSearch);
	var $yearInput = $('.input-hidden', $filterSearch);
	// concatenate string
	var $checkbox = $('.custom-control-input', $filterSearch);
	var $searchInput = $('.custom-search-input', $filterSearch);

	// reset button
	var $reset = $('.filter-clear', $filterSearch);

	// search button
	let $searchBtn = $('.filter-search-btn', $filterSearch);

	var concatenatedYear = '';
	var years = [];

	// years = ['2018', '2017', '2016'];

	$checkbox.each(function() {
		var $this = $(this);

		$this.on('change', function() {

			if ($this.val() == 'ALL') {
				if ($this.is(':checked')) {
					$checkbox.prop('checked', true);
					$yearInput.val('ALL');
				} else {
					$checkbox.prop('checked', false);
					$yearInput.val('');
				}
			} else {
				years = [];
				if (!$this.is(':checked')) {
					$('#ALL').prop('checked', false);
				}
				$checkbox.each(function() {
					var $this2 = $(this);

					if ($this2.is(':checked') && $this2.val() !== 'ALL') {
						years.push($this2.val());

					}
				});
				concatenatedYear = years.join('-');
				$yearInput.val(concatenatedYear);
			}
			$filterSearchForm.submit();

		});

	});

	$searchBtn.on('click', function(e){
		e.preventDefault();
		let $filterSearch = $('.filter-search'),
			$queryText = $('input[name="query"]').val(),
			$checkbox = $('.custom-control-input:checked', $filterSearch),
			current_url = window.location.origin,
			current_pathname = window.location.pathname,
			$years = [];

		console.log(`${current_url}${current_pathname}`);

		// $checkbox.each(function() {
		// 	let $this = $(this);

		// 	if ($this.is(':checked') && $this.val() !== 'ALL') {
		// 		$years.push($this.val());
		// 	}

		// 	let $currentYears = $years.join('-');
		// 	$yearInput.val($currentYears);

		// 	console.log($yearInput);
		// });

		let $checkboxVal = $checkbox.map(function() {return this.value;}).get().join('-');

		console.log($checkboxVal);
		//get current checked value

		if ($queryText) {
	        window.location.href = `${current_url}${current_pathname}?query=${$queryText}&year=${$checkboxVal}`;
      	}
		//append to url as ?query=value entered in text field

		//check if year is checked
		//if checked, the append to above url as &year=year values

		//url https://www.istana.gov.sg/newsroon?query=text
		//url &year=years
	});

	$reset.on('click', function(e){
		e.preventDefault();
		$checkbox.prop('checked', false);
		$yearInput.val('');
		$searchInput.val('');
		$filterSearchForm.submit();
	});


	function initOther(){
		if( $('.side-filter .filter-header a').length !== 0){

			if ($(window).width() > 767) {
				$('.side-filter .filter-header a').removeAttr('href data-toggle aria-expanded aria-controls');
				$('.side-filter .filter-header a').removeClass('collapsed');
				$('.side-filter .filter-search').removeClass('collapse');
			} else {
				$('.side-filter .filter-header a').attr({ 'href': '#filter', 'data-toggle': 'collapse', 'aria-expanded': 'false', 'aria-controls': 'filter' });
				$('.side-filter .filter-header a').addClass('collapsed');
				$('.side-filter .filter-search').addClass('collapse');
			}


			$(window).on('resize', function () {
				if ($(window).width() > 767) {
					$('.side-filter .filter-header a').removeAttr('href data-toggle aria-expanded aria-controls');
					$('.side-filter .filter-header a').removeClass('collapsed');
					$('.side-filter .filter-search').removeClass('collapse');
					$('.side-filter .filter-search').removeClass('show');
				} else {
					$('.side-filter .filter-header a').attr({ 'href': '#filter', 'data-toggle': 'collapse', 'aria-expanded': 'false', 'aria-controls': 'filter' });
					$('.side-filter .filter-header a').addClass('collapsed');
					$('.side-filter .filter-search').addClass('collapse');
				}
			});

		}
		if($(".newsroom-list .list-item").length){
			var length = $(".newsroom-list .list-item").length; //total #
			var acc = 10; //initial # to be displayed
			var prev = 0; //previous #
			$('.newsroom-list .list-item:lt(' + acc + ')').css({ 'display': 'block' });
		}

		if($(".bg-section.engagement .engagement-item").length){
			var length = $(".bg-section.engagement .engagement-item").length; //total #
			var acc = 6; //initial # to be displayed
			var prev = 0; //previous #
			$('.bg-section.engagement .engagement-item:lt(' + acc + ')').css({'display':'flex'});
		}


		//fallback for ie object fit
		var userAgent, ieReg, ie;
		userAgent = window.navigator.userAgent;
		ieReg = /msie|Trident.*rv[ :]*11\./gi;
		ie = ieReg.test(userAgent);

		if ( ie ) {
			$('.grid-item__image').each(function () {
				var $container = $(this),
				imgUrl = $container.find('img').prop('src');
				if (imgUrl) {
					$container
					.css('backgroundImage', 'url(' + imgUrl + ')')
					.addClass('compact-object-fit');
				}
			});
		}
	}

	initOther()


	if($('.ground .photoimg').length){
		$(window).on('resize', function () {
			if ($(window).width() < 576) {
				$.fancybox.defaults.thumbs.autoStart = false;
			} else {
				$.fancybox.defaults.thumbs.autoStart = true;
				$.fancybox.defaults.thumbs.axis = 'x';
			}
			});
			if ($(window).width() < 576) {
				$.fancybox.defaults.thumbs.autoStart = false;
			} else {
				$.fancybox.defaults.thumbs.autoStart = true;
				$.fancybox.defaults.thumbs.axis = 'x';
			}

			var items = $('.ground .photoimg');
			items.each(function () {
				var $this = $(this),
				itemClass = $this.find('a').attr('class');
				console.log(itemClass);
				if (itemClass) {
					$('.' + itemClass).fancybox({
						clickContent: false,
						autoSize: true,
						slideShow: {
							autoStart: true,
							speed: 3000
						}
					});
				}
			});
	}

	$('.modal').on('shown.bs.modal', function (e) {
  		 $(this).siblings('a[data-toggle="modal"]').css('color','#D98D8E');
    })

	if($(".bg-section.engagement .engagement-item").length){
		var length = $(".bg-section.engagement .engagement-item").length; //total #
		var acc = 6; //initial # to be displayed
		var prev = 0; //previous #
		$('.bg-section.engagement .engagement-item:lt(' + acc + ')').css({'display':'flex'});
	}


	if($("#mapdivContact").length){
		let mapLat = $('#mapdivContact').data('lat'),
		mapLng = $('#mapdivContact').data('lng');
		// We'll add a tile layer to add to our map, in this case it's a OSM tile layer.
		// Creating a tile layer usually involves setting the URL template for the tile images
		var osmUrl = 'https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png',
		osmAttrib = '<img src="https://docs.onemap.sg/maps/images/oneMap64-01.png" style="height:20px;width:20px;"/> New OneMap | Map data © contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>',
		osm = L.tileLayer(osmUrl, {
		maxZoom: 18,
		minZoom: 11,
		attribution: osmAttrib
		});
		// initialize the map on the "map" div with a given center and zoom
		var map = L.map('mapdivContact').setView([mapLat, mapLng], 17).addLayer(osm);
		var place = {
		lat: mapLat,
		lng: mapLng
		}
		// var myIcon = L.icon({
		//     iconUrl: 'Cwp/assets/istana/img/marker-icon.png',
		// 	shadowUrl: 'Cwp/assets/istana/img/marker-shadow.png',
		// 	iconRetinaUrl: 'Cwp/assets/istana/img/marker-icon.png',
		// 	shadowRetinaUrl: 'Cwp/assets/istana/img/marker-shadow.png'
		// });
		L.marker(place, {
		draggable: false,
		title: "Istana",
		alt: "Istana",
		riseOnHover: true
		}).addTo(map)
	}


	if($("#mapdivHeritage").length){
		let mapLat = $('#mapdivHeritage').data('lat'),
		mapLng = $('#mapdivHeritage').data('lng');
		console.log(mapLat);
		// We'll add a tile layer to add to our map, in this case it's a OSM tile layer.
		// Creating a tile layer usually involves setting the URL template for the tile images
		var osmUrl = 'https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png',
		osmAttrib = '<img src="https://docs.onemap.sg/maps/images/oneMap64-01.png" style="height:20px;width:20px;"/> New OneMap | Map data © contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>',
		osm = L.tileLayer(osmUrl, {
		maxZoom: 18,
		minZoom: 11,
		attribution: osmAttrib
		});
		// initialize the map on the "map" div with a given center and zoom
		var map = L.map('mapdivHeritage').setView([mapLat, mapLng], 17).addLayer(osm);
		var place = {
		lat: mapLat,
		lng: mapLng
		}
		L.marker(place, {
		draggable: false,
		title: "Istana Heritage Gallery",
		alt: "Istana Heritage Gallery",
		riseOnHover: true
		}).addTo(map)


		$(window).on('resize', function(){
			if ($(window).width() < 576) {
			$.fancybox.defaults.thumbs.autoStart = false;
			}else{
			$.fancybox.defaults.thumbs.autoStart = true;
			// $.fancybox.defaults.thumbs.axis = 'x';
			}
			});
			if ($(window).width() < 576) {
			$.fancybox.defaults.thumbs.autoStart = false;
			}else{
			$.fancybox.defaults.thumbs.autoStart = true;
			// $.fancybox.defaults.thumbs.axis = 'x';
			}
			$(".ihg").fancybox({
			clickContent: false,
			autoSize: true,
			slideShow: {
			autoStart: true,
			speed: 3000
			}
			});
	}


	
});

window.onunload = function(){
	console.log('window onunload')
};