
function StyleMastHead() {
  const style = document.createElement('style');
  style.innerHTML = `
  @media (min-width: 576px) { .container {max-width: 100% !important;} }
  @media (min-width: 768px) { .container {max-width: 100% !important;} }
  @media (min-width: 992px) { .container {max-width: 100% !important;padding-left:30px !important;padding-right:30px !important;} }
  @media (min-width: 1200px) { .container {max-width: 100% !important;padding-left:30px !important;padding-right:30px !important;} }
  @media (min-width: 1400px) { .container {max-width: 100% !important; padding-left:30px !important;padding-right:30px !important;} }
  @media (min-width: 1600px) { .container {max-width: 1600px !important; padding-left:30px !important;padding-right:30px !important;} }

  @media (min-width: 576px) { #sgds-masthead-content.container {max-width: 1600px  !important;} }
  @media (min-width: 768px) { #sgds-masthead-content.container {max-width: 1600px  !important;} }
  @media (min-width: 992px) { #sgds-masthead-content.container {max-width: 1600px  !important;} }
  @media (min-width: 1200px) { #sgds-masthead-content.container {max-width: 1600px !important;} }
  @media (min-width: 1400px) { #sgds-masthead-content.container {max-width: 1600px !important;} }
  
   .content-grid { max-width: 1320px !important;} 
  `;

  document.querySelector('sgds-masthead').shadowRoot.appendChild(style);
}

$(document).ready(function () {
  StyleMastHead();
});