loadGridTiles();
function loadGridTiles() {
setTimeout(function(){ 
    function addDataIndexToExpandedSections() {
        $('.tile').each(function(i) {
            $(this).find('.expanded-section').attr('data-index', i);
        });
    }

    // function tileContentPosition() {
    //     $('.tile').each(function() {
    //         if ($(window).width() <= 767) {
    //             $(this).find('.tile-content').insertAfter($(this).find('.card-body'));
    //         } else {
    //             $(this).find('.tile-content').appendTo($(this).find('.tile-bg'));
    //         }
    //     });
    // }

    // $(window).resize(function() {
    //     tileContentPosition();
    // });

    function cardHeightCalc(selector, heightCalc, widthCalc) {
        var selectorHeights,
            selectorHeight,
            selectorWidths,
            selectorWidth;

        $('.' + selector).css({
            'height': 'auto',
            'min-height': 'inherit'
        });

        if (heightCalc === true) {
            selectorHeights = $('.' + selector).map(function() {
                return $(this).innerHeight();
            }).get();
            selectorHeight = Math.max.apply(null,  selectorHeights);
        }

        if (widthCalc === true) {
            selectorWidths = $('.' + selector).map(function() {
                return $(this).innerWidth();
            }).get();
            selectorWidth = Math.max.apply(null,  selectorWidths);
        }

        return [selectorHeight, selectorWidth];
    }

    function cardSizeFunc() {
        var tileFooter = cardHeightCalc('card-body', true, false),
            tileBody = cardHeightCalc('tile-bg', true, true);

        $('.card-body').css('height', tileFooter[0] + 'px');

        // $('.tile-bg').css({
        //     'min-height': tileBgHeight[1] - tileFootHeight[0] + 'px',
        //     'height': tileBgHeight[0] + 'px'
        // });

        // if ($(window).width() <= 767) {
        //     var tileContentHeight = tileHeightCalc('tile-content', true, false);
        //     $('.tile-content').css('height', tileContentHeight[0] + 'px');
        // } else {
        //     $('.tile-content').css('height', '100%');
        // }
    }

    $(window).resize(function() {
        cardSizeFunc();
    });

    /*Display the number of cards per row for different screen resolutions */
    var cardsPerRow;
    function cardsPerRowFunc() {
        if ($(window).width() < 576 && cardsPerRow != '1') {
            cardsPerRow = '1';
            expandedSectPosition();
            return;
        }
        if ($(window).width() > 575 && $(window).width() < 768 && cardsPerRow != '2') {
            cardsPerRow = '2';
            expandedSectPosition();
            return;
        }
        if ($(window).width() > 767 && $(window).width() < 992 && cardsPerRow != '3') {
            cardsPerRow = '3';
            expandedSectPosition();
            return;
        }
        if ($(window).width() > 991 && cardsPerRow != '4') {
            cardsPerRow = '4';
            expandedSectPosition();
            return;
        }
    }

    $(window).resize(function() {
        cardsPerRowFunc();
    });
	
    /*Animation on auto scroll up to the footer area of the card when the card are clicked to display the expanded area*/
    function scrollToExpandedSect() {
        var offsetSelectorHeight = $('.sticky-top').innerHeight(),
            scrollToSelector = $('.tile.active .card-body');

        if ($(window).width() < 992) {
            offsetSelectorHeight = $('.sticky-top').innerHeight();
        }

        if ($(window).width() > 399 && $(window).width() < 767) {
            // scrollToSelector = $('.expanded-section.opened');
            scrollToSelector = $('.tile.active .card-body');
        }

        $('html, body').animate({
            scrollTop: Math.ceil((scrollToSelector.offset().top + 2) - offsetSelectorHeight)
        }, 400, function() {
            var offsetSelectorHeight = $('.sticky-top').innerHeight();

            if ($(window).width() < 992) {
                offsetSelectorHeight = $('.sticky-top').innerHeight();
            }

            $('html, body').stop(true, true).animate({
                scrollTop: Math.ceil((scrollToSelector.offset().top + 2) - offsetSelectorHeight)
            }, 250);
        });
    }

    /*Postion of the expanded section*/
    function expandedSectPosition() {
        if ($('.expanded-section.opened').length) {
            var cardIndex = Number($('.expanded-section.opened').attr('data-index')) + 1,
                expandedSecttionIndex = Math.abs(Math.ceil(cardIndex / cardsPerRow)) * cardsPerRow;

            if (expandedSecttionIndex > $('.tile').length) {
                expandedSecttionIndex = $('.tile').length;
            }

            $('.expanded-section.opened').insertAfter($('.tile').eq(expandedSecttionIndex - 1));
        }
    }

    /*fuctions on toggle the expanded section to close or open*/
    function togExpandedSect(clickedSelector) {
        var clickedCard = clickedSelector,
            clickedCardExpandedSection = clickedCard.find('.expanded-section'),
            cardIndex = $('.tile').index(clickedSelector) + 1,
            expandedSecttionIndex = Math.abs(Math.ceil(cardIndex / cardsPerRow)) * cardsPerRow;

        if (expandedSecttionIndex > $('.tile').length) {
            expandedSecttionIndex = $('.tile').length;
        }

        if ($('.expanded-section.opened').length === 0 && clickedCardExpandedSection.length) {
            clickedCard.addClass('active');
            clickedCardExpandedSection
                .insertAfter($('.tile').eq(expandedSecttionIndex - 1))
                .addClass('opened')
                .stop(true, true)
                .slideDown('300', function() {
                    scrollToExpandedSect();
                });
        } else {
            $('.expanded-section.opened').stop(true, true).slideUp('200', function() {
                $('.tile.active').removeClass('active');
                $(this)
                    .appendTo($('.tile').eq($(this).attr('data-index')))
                    .removeClass('opened');

                if (clickedCard.index() != $(this).attr('data-index') && clickedCardExpandedSection.length) {
                    clickedCard.addClass('active');
                    clickedCardExpandedSection
                        .insertAfter($('.tile').eq(expandedSecttionIndex - 1))
                        .addClass('opened')
                        .stop(true, true)
                        .slideDown('300', function() {
                            scrollToExpandedSect();
                        });
                }
            });
        }
    }

    function gridSectionInit() {
        addDataIndexToExpandedSections();
        // tileContentPosition();
        cardSizeFunc();
        cardsPerRowFunc();
        expandedSectPosition();
        togExpandedSect($(this));
    }

    gridSectionInit();

    /*click on the tile/card or the close button can toggle the expanded section to close it.*/
    $('.tile, .close-btn').click(function() {
        togExpandedSect($(this));
    });
	
	}, 1000);
}
