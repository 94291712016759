$(document).ready(function() {
	var affectedElements = $(".bg-section");
	var isTextZoomTriggered = false;
	var textZoomLevel = 0;

	affectedElements.each(function() {
		// Store the original size in a data attribute so size can be reset
		$(this).data("original-size", $(this).css("font-size") );
	});

	$("#btn-increase").click(function() {
		if (!isTextZoomTriggered) {
		  SetOriginalFontSize(affectedElements);
		  isTextZoomTriggered = true;
		}
		if (textZoomLevel < 2) {
		  ChangeFontSize(2);
		  MatchHeight();
		  textZoomLevel++;
		}
		MatchHeight();  // Fired twice
	});
	$("#btn-decrease").click(function() {
		if (!isTextZoomTriggered) {
			SetOriginalFontSize(affectedElements);
			isTextZoomTriggered = true;
		}
		if (textZoomLevel > -2) {
			ChangeFontSize(-2);
			MatchHeight();
			textZoomLevel--;
		}
		MatchHeight();  // Fired twice
	});
	$("#btn-original").click(function() {
		SetOriginalFontSize(affectedElements);
		MatchHeight();
		textZoomLevel = 0;
		MatchHeight();
	});

	function ChangeFontSize(direction) {
		affectedElements.each(function() {
			$(this).css("font-size", parseInt($(this).css("font-size"))+direction);
		});
	}
	function SetOriginalFontSize(items) {
		items.each(function() {
			$(this).css("font-size", $(this).data("original-size"));
		});
	}
});
